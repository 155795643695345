import axios from "../../axios";


interface cameraDataResponse {
    data: Array<Array<string>>;
}

interface filteredDataResponse {
    data: Array<Array<string>>;
    sum: number;
}

export async function fetchCameraData(
    camera_name: string
): Promise<cameraDataResponse> {
    return await axios.get(`http://uta-mons.multitel.be/api/v1/data_timeseries.json?camera_name=${camera_name}`);
}

export async function fetchFilteredData(
    camera: string,
    intersection: string,
    allIntersections: boolean,
    fromDateTime: string,
    toDateTime: string,
    allHours: boolean,
    showIn: boolean,
    showOut: boolean
): Promise<filteredDataResponse> {
    const parameters = {
        params: {
            camera: camera,
            intersection: intersection,
            allIntersections: allIntersections,
            fromDateTime: fromDateTime,
            toDateTime: toDateTime,
            allHours: allHours,
            showIn: showIn,
            showOut: showOut
        }
    }
    return await axios.get("http://uta-mons.multitel.be/api/v1/filter-db.json", parameters);
}