import { combineReducers } from "redux";

// Reducers
import dataReducer from "./dataReducer";

// Loading Reducers
import isLoadingReducer from "./isLoadingReducer";

// Error Reducers
import errorReducer from "./errorReducer";


const rootReducer = combineReducers({
    data: dataReducer,
    isLoading: isLoadingReducer,
    error: errorReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;