import React, { FunctionComponent } from 'react';

// Reactstrap
import { Button, Collapse } from 'reactstrap';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

// Styles
import './ToggleJumbotron.css';


interface Props {
    open : boolean;
    onToggle : any;
}

const ToggleJumbotron: FunctionComponent<Props> = (props) => {
    const { open, onToggle } = props;

    return (
        <div className="m-toggle-jumbotron-container">
            <Button onClick={onToggle}>
                <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
            </Button>                        

            <Collapse isOpen={open}>
                {props.children}
            </Collapse>
            <hr hidden={!open} />
        </div>
    );
}

export default ToggleJumbotron;