import { put, call, takeEvery, all, fork } from "redux-saga/effects";

// Services (API)
import { fetchCameraData } from "../services/dataServices";

// Action Creators
import * as actionCreators from "../actionCreators/dataActionCreators";

// Action Types definition
import * as actionTypes from "../actionTypes/dataActionTypes";


function* onLoadCameraData({ camera_name }: actionTypes.GetCameraDataAction) {
  try {
    yield put(actionCreators.getCameraDataRequest());
    const { data } = yield call(fetchCameraData, camera_name);
    yield put(actionCreators.getCameraDataSuccess(data));
  } 
  catch (error) {
    yield put(actionCreators.getCameraDataFailure(error.response.data.error));
  }
}

function* watchOnLoadCameraData() {
  yield takeEvery(actionTypes.GET_CAMERA_DATA, onLoadCameraData);
}

export default function* cameraDataSaga() {
  yield all([fork(watchOnLoadCameraData)]);
}