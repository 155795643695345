import React, { FunctionComponent } from 'react';

// Styles
import './Spinner.css';


interface Props {
    active: boolean;
}

const Spinner: FunctionComponent<Props> = (props) => {
    const { active } = props;

    return (
        <div>
            <div className='spinner' hidden={!active} />
            <div hidden={active}>
                {props.children}
            </div>
        </div>
    );
}

export default Spinner;