import React, { Component } from 'react';

// Reactstrap
import { Input, FormGroup, Label, Button, Container, Row, Col } from 'reactstrap';

// React widgets
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

// Components
import CameraSelect from '../CameraSelect/CameraSelect';

// Constants
import { cameraOptions, intersectionOptions } from '../constants'

// Styles
import 'react-widgets/dist/css/react-widgets.css'
import './ToggleJumbotron.css';

// Configure react-widgets locales
momentLocalizer();


interface Props {
    onNewData : any;
}

interface State {
    selectedCamera : string;
    selectedIntersection : string;
    allIntersections : boolean;
    fromDateTime : Date;
    toDateTime : Date;
    allHours : boolean;
    showIn : boolean;
    showOut : boolean;
    isCollapseOpen : boolean;
};

class FilterForm extends Component<Props, State> {
    state: State = {
        selectedCamera: "camera_1",
        selectedIntersection: "HAVRE",
        allIntersections: true,
        fromDateTime: new Date(new Date().setHours(8, 0 , 0)),
        toDateTime: new Date(new Date().setHours(18, 0 , 0)),
        allHours: true,
        showIn: true,
        showOut: false,
        isCollapseOpen : true
    }

    onSelectCamera = (event) => {
        const new_camera = event.target.value;
        const first_intersection = intersectionOptions.filter(el => el.camera === new_camera)[0].key;
        this.setState({selectedCamera: new_camera, selectedIntersection: first_intersection});
    }

    onSelectIntersection = (event) => this.setState({selectedIntersection: event.target.value});
    onToggleAllInterserctions = () => this.setState({allIntersections: !this.state.allIntersections});
    onToggleAllHours = () => this.setState({allHours: !this.state.allHours});
    onToggleInside = () => this.setState({showIn: !this.state.showIn});
    onToggleOutside = () => this.setState({showOut: !this.state.showOut});

    onLoadData = () => {
        const {
            selectedCamera,
            selectedIntersection,
            allIntersections,
            fromDateTime,
            toDateTime,
            allHours,
            showIn,
            showOut
        } = this.state;

        this.props.onNewData(
            selectedCamera,
            selectedIntersection,
            allIntersections,
            fromDateTime,
            toDateTime,
            allHours,
            showIn,
            showOut
        );
    }

    render () {
        const {
            selectedCamera,
            selectedIntersection,
            allIntersections,
            fromDateTime,
            toDateTime,
            allHours,
            showIn,
            showOut
        } = this.state;

        const possibleIntersections = intersectionOptions.filter(el => el.camera === selectedCamera);

        return (
            <Container>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Caméra</Label>
                            <CameraSelect options={cameraOptions} selected={selectedCamera} onChange={this.onSelectCamera}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={allIntersections} onChange={this.onToggleAllInterserctions}/>{' '}
                                Toutes les intersections
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup hidden={allIntersections}>
                            <Label>Intersection</Label>
                            <CameraSelect options={possibleIntersections} selected={selectedIntersection} onChange={this.onSelectIntersection}/>
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>De</Label>
                            <DateTimePicker
                                value={fromDateTime}
                                format={"DD/MM/YY HH:mm"}
                                onChange={value => this.setState({ fromDateTime: value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>À</Label>
                            <DateTimePicker
                                value={toDateTime}
                                format={"DD/MM/YY HH:mm"}
                                min={fromDateTime}
                                onChange={value => this.setState({ toDateTime: value })}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={allHours} onChange={this.onToggleAllHours}/>{' '}
                                Toutes les heures
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={showIn} onChange={this.onToggleInside}/>{' '}
                                Intérieure
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={showOut} onChange={this.onToggleOutside}/>{' '}
                                Extérieur
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button block style={{float: "right"}} onClick={this.onLoadData}>Charger</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
    
}

export default FilterForm;