export const SET_CAMERA_DATA = "cameraDataActionTypes/SET_CAMERA_DATA";
export const GET_CAMERA_DATA = "cameraDataActionTypes/GET_CAMERA_DATA";
export const GET_CAMERA_DATA_REQUEST = "cameraDataActionTypes/GET_CAMERA_DATA_REQUEST";
export const GET_CAMERA_DATA_SUCCESS = "cameraDataActionTypes/GET_CAMERA_DATA_SUCCESS";
export const GET_CAMERA_DATA_FAILURE = "cameraDataActionTypes/GET_CAMERA_DATA_FAILURE";

export const SET_FILTERED_DATA = "cameraDataActionTypes/SET_FILTERED_DATA";
export const GET_FILTERED_DATA = "cameraDataActionTypes/GET_FILTERED_DATA";
export const GET_FILTERED_DATA_REQUEST = "cameraDataActionTypes/GET_FILTERED_DATA_REQUEST";
export const GET_FILTERED_DATA_SUCCESS = "cameraDataActionTypes/GET_FILTERED_DATA_SUCCESS";
export const GET_FILTERED_DATA_FAILURE = "cameraDataActionTypes/GET_FILTERED_DATA_FAILURE";

export interface SetCameraDataAction {
  type: typeof SET_CAMERA_DATA;
  data: Array<Array<string>>;
}
export interface GetCameraDataAction {
  type: typeof GET_CAMERA_DATA;
  camera_name: string;
}
export interface GetCameraDataRequestAction {
  type: typeof GET_CAMERA_DATA_REQUEST;
}
export interface GetCameraDataSuccessAction {
  type: typeof GET_CAMERA_DATA_SUCCESS;
  data: Array<Array<string>>;
}
export interface GetCameraDataFailureAction {
  type: typeof GET_CAMERA_DATA_FAILURE;
  error: Error | string;
}

// ============================================================
export interface SetFilteredDataAction {
  type: typeof SET_FILTERED_DATA;
  data: Array<Array<string>>;
  sum: number;
}
export interface GetFilteredDataAction {
  type: typeof GET_FILTERED_DATA;
  camera: string;
  intersection: string;
  allIntersections: boolean;
  fromDateTime: string;
  toDateTime: string;
  allHours: boolean;
  showIn: boolean;
  showOut: boolean;
}
export interface GetFilteredDataRequestAction {
  type: typeof GET_FILTERED_DATA_REQUEST;
}
export interface GetFilteredDataSuccessAction {
  type: typeof GET_FILTERED_DATA_SUCCESS;
  data: Array<Array<string>>;
  sum: number;
}
export interface GetFilteredDataFailureAction {
  type: typeof GET_FILTERED_DATA_FAILURE;
  error: Error | string;
}

export type CameraDataAction =
  | SetCameraDataAction
  | GetCameraDataAction
  | GetCameraDataRequestAction
  | GetCameraDataSuccessAction
  | GetCameraDataFailureAction
  | SetFilteredDataAction
  | GetFilteredDataAction
  | GetFilteredDataRequestAction
  | GetFilteredDataSuccessAction
  | GetFilteredDataFailureAction;