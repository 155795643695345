import React, { FunctionComponent } from 'react';

// Styles
import './TitleJumbotron.css';


interface Props {
  title : string;
}

const TitleJumbotron: FunctionComponent<Props> = (props) => {

  const { title } = props;

  return (
      <div className='m-title-background'>
        <div className='m-title-text'>{title}</div>
      </div>
    );
}

export default TitleJumbotron;