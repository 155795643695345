import * as actions from "../actionTypes/dataActionTypes";


export interface CameraDataState {
  camera_name: string;
  data: Array<Array<string>>;
  filtered_data: Array<Array<string>>;
  sum: number;
}

const initialState: CameraDataState = {
  camera_name: "camera_1",
  data: [],
  filtered_data: [],
  sum: 0
};

export default function dataReducer( 
  state: CameraDataState = initialState, 
  action: actions.CameraDataAction 
  ): CameraDataState {
    switch (action.type) {
      case actions.SET_CAMERA_DATA:
        return state;
      case actions.GET_CAMERA_DATA_SUCCESS:
        return {
          ...state,
          data: action.data
        };
      case actions.SET_FILTERED_DATA:
        return state;
      case actions.GET_FILTERED_DATA_SUCCESS:
        return {
          ...state,
          filtered_data: action.data,
          sum: action.sum
        };
      default:
        return state;
  }
}