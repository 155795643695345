import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";

// Variables
import { ROOT, DASHBOARD, FILTER } from "./routes";

// Containers
import Graph from './Graph';
import FilteredResults from './FilteredResults';

// Components
import NavBar from './NavBar/NavBar';
import Footer from './Footer/Footer';

// Styles
import './App.css';

interface Props {}
interface State {};


class App extends Component<Props, State> {

  render () {
    return (
      <div className="m-global-container">
        <div className="m-content-before-footer">
          <NavBar />
          <Switch>
              <Route exact path={ROOT} component={Graph} />
              <Route path={DASHBOARD} component={Graph} />
              <Route path={FILTER} component={FilteredResults} />
          </Switch>
          <div className="m-footer-offset"></div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;