import * as actions from "../actionTypes/dataActionTypes";


export function setCameraData(data: Array<Array<string>>): actions.SetCameraDataAction {
  return {
    type: actions.SET_CAMERA_DATA,
    data
  };
}

export function getCameraData(camera_name: string): actions.GetCameraDataAction {
  return {
    type: actions.GET_CAMERA_DATA,
    camera_name
  };
}

export function getCameraDataRequest(): actions.GetCameraDataRequestAction {
  return {
    type: actions.GET_CAMERA_DATA_REQUEST
  };
}

export function getCameraDataSuccess(data: Array<Array<string>>): actions.GetCameraDataSuccessAction {
  return {
    type: actions.GET_CAMERA_DATA_SUCCESS,
    data
  };
}

export function getCameraDataFailure(error: Error | string): actions.GetCameraDataFailureAction {
  return {
    type: actions.GET_CAMERA_DATA_FAILURE,
    error
  };
}

//=====================================================================================================

export function setFilteredData(data: Array<Array<string>>, sum: number): actions.SetFilteredDataAction {
  return {
    type: actions.SET_FILTERED_DATA,
    data,
    sum
  };
}

export function getFilteredData(
  camera: string,
  intersection: string,
  allIntersections: boolean,
  fromDateTime: string,
  toDateTime: string,
  allHours: boolean,
  showIn: boolean,
  showOut: boolean
  ): actions.GetFilteredDataAction {
  return {
    type: actions.GET_FILTERED_DATA,
    camera,
    intersection,
    allIntersections,
    fromDateTime,
    toDateTime,
    allHours,
    showIn,
    showOut
  };
}

export function getFilteredDataRequest(): actions.GetFilteredDataRequestAction {
  return {
    type: actions.GET_FILTERED_DATA_REQUEST
  };
}

export function getFilteredDataSuccess(data: Array<Array<string>>, sum: number): actions.GetFilteredDataSuccessAction {
  return {
    type: actions.GET_FILTERED_DATA_SUCCESS,
    data,
    sum
  };
}

export function getFilteredDataFailure(error: Error | string): actions.GetFilteredDataFailureAction {
  return {
    type: actions.GET_FILTERED_DATA_FAILURE,
    error
  };
}