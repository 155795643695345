import React, { useState, FunctionComponent } from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import { Link } from "react-router-dom";

import { ROOT, DASHBOARD, FILTER } from "../routes";

import './NavBar.css';

import logo from '../media/multitel.png';


const NavBar: FunctionComponent<RouteComponentProps> = (routeProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const multitel_url = "http://www.multitel.be";
    const pathname = routeProps.location.pathname;

    return (
        <Navbar color="dark" dark expand="md">
            <NavbarBrand href={multitel_url} target="_blank">
                <img alt="Multitel" src={logo} />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                    <NavItem active={pathname===ROOT || pathname===DASHBOARD}>
                        <NavLink tag={Link} to={DASHBOARD}>Tableau de bord</NavLink>
                    </NavItem>
                    <NavItem active={pathname===FILTER}>
                        <NavLink tag={Link} to={FILTER}>Filtre</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}

export default withRouter(NavBar);