// Redux
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// Saga
import rootReducer from "../reducers/rootReducer";
import rootSaga from "../sagas/rootSaga";


//Create a Saga Middleware
const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;