import React, { Component } from 'react';

// Redux
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { AppState } from "./core/reducers/rootReducer";
import * as actionTypes from "./core/actionTypes/dataActionTypes";
import { getCameraData } from './core/actionCreators/dataActionCreators';

// Reactstrap
import { Container } from 'reactstrap';

// Constants
import { cameraOptions } from './constants'

// Components
import TimeChart from './TimeChart/TimeChart';
import TitleJumbotron from './TitleJumbotron/TitleJumbotron';
import Spinner from './Spinner/Spinner';
import CameraSelect from './CameraSelect/CameraSelect';


interface Props {
  onGetCameraData(camera_name: string): void;
  error: null | Error | string;
  isLoading: boolean;
  data: Array<Array<string>>;
}

interface State {
  selectedCamera : string;
};

class Graph extends Component<Props, State> {

  state: State = {
    selectedCamera: "camera_1",
  }

  componentDidMount = () => {
    this.props.onGetCameraData(this.state.selectedCamera);
  }

  reloadCameraData = (event) => {
    const new_camera = event.target.value;
    this.setState({selectedCamera: new_camera});
    this.props.onGetCameraData(new_camera);
  }

  getCameraTitle = (selectedCamera) => {
    const cameraOption = cameraOptions.find(el => el.key === selectedCamera);
    return cameraOption ? cameraOption.title : cameraOptions[0].title;
  }

  render () {

    const { isLoading, data } = this.props;
    const { selectedCamera } = this.state;
    
    const title = this.getCameraTitle(selectedCamera);

    return (
      <div>
        <TitleJumbotron title={title} />
        <Spinner active={isLoading}>
          <Container>
            <CameraSelect options={cameraOptions} selected={selectedCamera} onChange={this.reloadCameraData}/>
            <TimeChart
              title={"Personnes entrées dans la zone par heure (e.g.: 15h00 -> 14h00 - 15h00)"}
              data={data} />
          </Container>
        </Spinner>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    data: state.data.data,
    isLoading: state.isLoading[actionTypes.GET_CAMERA_DATA],
    error: state.error[actionTypes.GET_CAMERA_DATA]
  };
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.CameraDataAction>) => ({
  onGetCameraData: (camera_name: string) => {dispatch(getCameraData(camera_name))}
});

export default connect(mapStateToProps, mapDispatchToProps)(Graph);