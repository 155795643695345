import { put, call, takeEvery, all, fork } from "redux-saga/effects";

// Services (API)
import { fetchFilteredData } from "../services/dataServices";

// Action Creators
import * as actionCreators from "../actionCreators/dataActionCreators";

// Action Types definition
import * as actionTypes from "../actionTypes/dataActionTypes";


function* onLoadFilteredData({ 
  camera,
  intersection,
  allIntersections,
  fromDateTime,
  toDateTime,
  allHours,
  showIn,
  showOut
}: actionTypes.GetFilteredDataAction) {
  try {
    yield put(actionCreators.getFilteredDataRequest());
    const { data } = yield call(fetchFilteredData, camera, intersection, allIntersections, fromDateTime, toDateTime, allHours, showIn,showOut);
    yield put(actionCreators.getFilteredDataSuccess(data.data, data.sum));
  } 
  catch (error) {
    yield put(actionCreators.getFilteredDataFailure(error.response.data.error));
  }
}

function* watchOnLoadFilteredData() {
  yield takeEvery(actionTypes.GET_FILTERED_DATA, onLoadFilteredData);
}

export default function* filteredDataSaga() {
  yield all([fork(watchOnLoadFilteredData)]);
}