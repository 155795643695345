import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

// Redux
import { Provider } from "react-redux";
import configureStore from "./core/store/configureStore";

// Containers
import App from './App';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>, 
    document.getElementById('root')
);

serviceWorker.unregister();
