import React, { FunctionComponent } from 'react';

// Reactstrap
import { Input } from 'reactstrap';

// Styles
import './CameraSelect.css';


interface Props {
  options : any;
  onChange : any;
  selected : string;
}

const CameraSelect: FunctionComponent<Props> = (props) => {
    const { options, selected, onChange } = props;
    return (
        <div className="m-camera-select-container">
            <Input type="select" value={selected} onChange={onChange}>
                {options.map((camera : any) => <option key={camera.key} value={camera.key}>{camera.title}</option>)}
            </Input>
        </div>
    );
}

export default CameraSelect;