import React, { Component } from 'react';

// Reactstrap
import { Container } from 'reactstrap';

// Redux
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { AppState } from "./core/reducers/rootReducer";
import * as actionTypes from "./core/actionTypes/dataActionTypes";
import { getFilteredData } from './core/actionCreators/dataActionCreators';

// Components
import ToggleJumbotron from './FilterForm/ToggleJumbotron';
import FilterForm from './FilterForm/FilterForm';
import TimeChart from './TimeChart/TimeChart';
import NumberCard from './NumberCard/NumberCard';
import Spinner from './Spinner/Spinner';
// import ResultsTable from './ResultsTable/ResultsTable';


interface Props {
    filtered_data: Array<Array<string>>;
    filtered_sum: number;
    isLoading: boolean;
    error: null | Error | string;
    onGetFilteredData: any;
}
interface State {
    isCollapseOpen : boolean;
};

class FilteredResults extends Component<Props, State> {

    state: State = {
        isCollapseOpen : true,
    }

    onToggleCollapse = () => this.setState({ isCollapseOpen: !this.state.isCollapseOpen });
    onLoadData = (camera, intersection, allIntersections, fromDateTime, toDateTime, allHours, showIn, showOut) => {
        this.props.onGetFilteredData(camera, intersection, allIntersections, fromDateTime, toDateTime, allHours, showIn, showOut);
        this.setState({isCollapseOpen: false});
    };

    render () {
        const {
            isCollapseOpen
        } = this.state;

        const { isLoading, filtered_data, filtered_sum } = this.props;

        return (
            <div>
                <ToggleJumbotron open={isCollapseOpen} onToggle={this.onToggleCollapse}>
                    <FilterForm onNewData={this.onLoadData} />
                </ToggleJumbotron>
               
                <Container>
                    <Spinner active={isLoading}>
                        <NumberCard color={"#e52b50"} title={"Somme"} number={filtered_sum} />
                        <TimeChart
                            title={"Flux de personnes par heure (e.g.: 15h00 -> 14h00 - 15h00)"}
                            data={filtered_data} />
                        {/* <ResultsTable data={filtered_data} /> */}
                        </Spinner>
                </Container>
            </div>
        );
    }
}


const mapStateToProps = (state: AppState) => {
    return {
      filtered_data: state.data.filtered_data,
      filtered_sum: state.data.sum,
      isLoading: state.isLoading[actionTypes.GET_FILTERED_DATA],
      error: state.error[actionTypes.GET_FILTERED_DATA]
    };
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.GetFilteredDataAction>) => ({
    onGetFilteredData: (
        camera: string,
        intersection: string,
        allIntersections: boolean,
        fromDateTime: string,
        toDateTime: string,
        allHours: boolean,
        showIn: boolean,
        showOut: boolean
    ) => {dispatch(getFilteredData(camera, intersection, allIntersections, fromDateTime, toDateTime, allHours, showIn, showOut))}
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FilteredResults);