import React, { FunctionComponent } from 'react';

import './NumberCard.css';


interface Props {
    color: string;
    title: string;
    number: number;
}

const NumberCard: FunctionComponent<Props> = (props) => {
    const { title, number, color } = props;

    return (
        <div hidden={!number} className="m-number-card" style={{backgroundColor: color}}>
            {title}: {number}
        </div>
    );
}

export default NumberCard;