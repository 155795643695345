import React, { FunctionComponent } from 'react';

// Highcharts
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official';

// Load Highcharts modules
// require('highcharts/indicators/indicators')(Highcharts)
// require('highcharts/indicators/pivot-points')(Highcharts)
// require('highcharts/indicators/macd')(Highcharts)
// require('highcharts/modules/exporting')(Highcharts)
// require('highcharts/modules/map')(Highcharts)


interface Props {
    title: string;
    data: Array<Array<string>>;
}

const TimeChart: FunctionComponent<Props> = (props) => {
    const { data, title } = props;

    const options: Highcharts.Options = {
        chart: {
            height: 500,
        },
        title: {
            text: title
        },
        tooltip: {
            xDateFormat: "%e %b %Y , %H:%M"
        },
        series: [{
            type: 'line',
            name: 'personnes/heure',
            data: data
        }]
    }

    const visible = data !== null && data.length !== 0;

    return (
        <div className="my-3">
            <div className="text-center my-2" hidden={visible}>Pas de données disponibles</div>
            <div hidden={!visible}>
                <HighchartsReact 
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                />
            </div>
        </div>
    );
}

export default TimeChart;