export const cameraOptions = [
    { key: "camera_1", title: "Caméra 1 - rue de la Clef, rue d'Havré et Grand-Place"},
    { key: "camera_2", title: "Caméra 2 - rue de la Coupe, rue de la Chaussée et Grand-Place"},
    { key: "camera_3", title: "Caméra 3 - rue de la Chaussée, rue Samson et Grand Rue"},
    { key: "camera_4", title: "Caméra 4 - rue des Capucins, rue de la petite boucherie et Grand Rue"},
]

export const intersectionOptions = [
    { key: "HAVRE", camera: "camera_1", title: "HAVRE"},
    { key: "CLEF", camera: "camera_1", title: "CLEF"},
    { key: "HAVRE_CLE_PLACE", camera: "camera_1", title: "HAVRE_CLE_PLACE"},

    { key: "COUPE", camera: "camera_2", title: "COUPE"},
    { key: "CHAUSSEE", camera: "camera_2", title: "CHAUSSEE"},
    { key: "PLACE", camera: "camera_2", title: "PLACE"},

    { key: "CHAUSSEE_BAS", camera: "camera_3", title: "CHAUSSEE_BAS"},
    { key: "FRIPIERS", camera: "camera_3", title: "FRIPIERS"},
    { key: "GRAND_RUE", camera: "camera_3", title: "GRAND_RUE"},
    { key: "SAMSON", camera: "camera_3", title: "SAMSON"},

    { key: "GRAND_RUE_HAUT", camera: "camera_4", title: "GRAND_RUE_HAUT"},
    { key: "PETITE_BOUCHERIE", camera: "camera_4", title: "PETITE_BOUCHERIE"},
    { key: "GRAND_RUE_BAS", camera: "camera_4", title: "GRAND_RUE_BAS"},
    { key: "CAPUCINS", camera: "camera_4", title: "CAPUCINS"},
]